import Lottie from "react-lottie-player";
import notFoundAnimation from "../assets/404-lottie.json";
import { Button } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        maxHeight: "85vh",
      }}
    >
      <Lottie
        style={{ maxWidth: "100%", maxHeight: "90%" }}
        play
        animationData={notFoundAnimation}
        loop
        rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
      />
      <Button
        onClick={() => navigate("/")}
        type="primary"
        icon={<HomeOutlined />}
        style={{ marginTop: "8px" }}
      >
        Home
      </Button>
    </div>
  );
};

export default NotFound;
