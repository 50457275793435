import client from "./client";

export const createActivity = async (data: any) => {
  const response = await client.post(
    `/activities`,
    { activity: data.activity },
    {
      headers: {
        Authorization: `Bearer ${await data.getToken()}`,
      },
    }
  );
  return response.data;
};

export const updateActivity = async (data: any) => {
  const response = await client.put(
    `/activities/${data.id}`,
    {
      activity: data.activity,
    },
    {
      headers: {
        Authorization: `Bearer ${await data.getToken()}`,
      },
    }
  );
  return response.data;
};

export const deleteActivity = async (data: any) => {
  const response = await client.delete(`/activities/${data.id}`, {
    headers: {
      Authorization: `Bearer ${await data.getToken()}`,
    },
  });
  return response.data;
};
