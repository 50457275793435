import { useQuery } from "@tanstack/react-query";
import { useAuth } from "@clerk/clerk-react";

export default function useClerkQuery(queryKey: string, url: string) {
  const { getToken } = useAuth();

  return useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      const token = await getToken();
      const res = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!res.ok) {
        throw new Error("Network response error");
      }

      return res.json();
    },
  });
}
