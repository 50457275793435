import { Button, Card, Col, List, message, Popconfirm, Row } from "antd";
import dayjs from "dayjs";
import {
  DeleteOutlined,
  // EditOutlined
} from "@ant-design/icons";
import { useState } from "react";
import { motion } from "framer-motion";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { dateFormatLong } from "../../constants";
import { deleteActivityLog } from "../../api/activityLogs";
import { useAuth } from "@clerk/clerk-react";

const ActivityLogHistoryCard = ({
  log,
}: {
  log: { date: string; activities: string[] };
}) => {
  const { getToken } = useAuth();
  const [deletingLog, setDeletingLog] = useState<undefined | string>(undefined);
  const queryClient = useQueryClient();
  const { isPending: pendingDelete, mutate: mutateDeleteActivityLog } =
    useMutation({
      mutationFn: deleteActivityLog,
      onSuccess: (data) => {
        message.success(data);
        queryClient.invalidateQueries({ queryKey: ["get-activity-logs"] });
      },
    });
  const [day, month, year] = log.date.split("/");
  const date: Date = new Date(`${year}-${month}-${day}`);
  return (
    <Col key={log.date} span={24}>
      <motion.div
        whileInView={{ opacity: 1, y: 0 }}
        initial={{ opacity: 0, y: 20 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      >
        <Card
          bordered={false}
          style={{
            boxShadow: "rgba(0, 0, 0, 0.08) 0px 4px 12px",
          }}
          key={log.date}
          title="Healthy Activities"
          extra={dayjs(date).format(dateFormatLong)}
        >
          <List
            size="small"
            split={false}
            dataSource={log.activities}
            renderItem={(activity) => (
              <List.Item style={{ padding: "3px 0" }} key={activity}>
                {activity}
              </List.Item>
            )}
          />
          <Row justify={"end"}>
            {/* <Button icon={<EditOutlined />} type="text" /> */}
            <Popconfirm
              placement="topLeft"
              title="Delete the activity log"
              description="Would you like to delete this log?"
              onConfirm={() => {
                setDeletingLog(log.date);
                mutateDeleteActivityLog({ date: log.date, getToken });
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                loading={pendingDelete && deletingLog === log.date}
                style={{
                  border: "none",
                  boxShadow: "none",
                }}
                danger
                type="text"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Row>
        </Card>
      </motion.div>
    </Col>
  );
};

export default ActivityLogHistoryCard;
