import { Route, Routes } from "react-router-dom";
import "./App.css";
import DefaultLayout from "./layouts/DefaultLayout";
import Dashboard from "./pages/Dashboard";
import {
  SignedIn,
  SignedOut,
  SignIn,
  useAuth,
  useUser,
} from "@clerk/clerk-react";
// import { AnimatePresence, motion } from "framer-motion";
import NotFound from "./pages/NotFound";
import CenteredSpin from "./components/common/CenteredSpin";
import ActivityLogs from "./pages/ActivityLogs";
import DiaryHistory from "./pages/DiaryHistory";
import UnderDevelopment from "./pages/UnderDevelopment";

function App() {
  const { isLoaded } = useAuth();
  const { isLoaded: isUserLoaded } = useUser();

  if (!isLoaded || !isUserLoaded) {
    return <CenteredSpin height={"100dvh"} size="large" />;
  }
  return (
    <>
      <SignedOut>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100dvh",
          }}
        >
          <SignIn />
        </div>
      </SignedOut>
      <SignedIn>
        {/* <AnimatePresence mode="wait"> */}
        <Routes>
          <Route element={<DefaultLayout />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/diary-history" element={<DiaryHistory />} />
            <Route path="/activity-log" element={<ActivityLogs />} />
            <Route path="/diary-entry" element={<UnderDevelopment />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
        {/* </AnimatePresence> */}
      </SignedIn>
    </>
  );
}

export default App;
