import axios from "axios";
// import { useAuth } from "@clerk/clerk-react";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000, // 10 seconds
});
// client.defaults.withCredentials = true;
client.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
//   process.env.REACT_APP_API_URL;

export default client;
