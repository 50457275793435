import { Button, Input, message, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createActivity } from "../../api/activities";
import { useAuth } from "@clerk/clerk-react";

const AddActivity = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string | undefined>(undefined);

  const handleCancel = () => {
    setValue(undefined);
    setOpen(false);
  };

  const { isPending, mutate } = useMutation({
    mutationFn: createActivity,
    onSuccess: (data: any) => {
      message.success(data);
      queryClient.invalidateQueries({ queryKey: ["get-activities"] });
      handleCancel();
    },
  });

  return (
    <>
      <Button
        shape="circle"
        onClick={() => setOpen(true)}
        style={{ border: "none", boxShadow: "none" }}
        icon={<PlusOutlined />}
      />
      <Modal
        okText="Add"
        onOk={() => {
          if (value) mutate({ activity: value, getToken });
        }}
        okButtonProps={{ loading: isPending }}
        title="Add a new activity"
        open={open}
        onCancel={handleCancel}
      >
        <Input value={value} onChange={(e) => setValue(e.target.value)} />
      </Modal>
    </>
  );
};

export default AddActivity;
