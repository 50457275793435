import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  List,
  message,
  Popconfirm,
  Row,
  Typography,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { dateFormat } from "../constants";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteActivity, updateActivity } from "../api/activities";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import CenteredSpin from "../components/common/CenteredSpin";
import AddActivity from "../components/activity/addActivity";
import { createActivityLog } from "../api/activityLogs";
import useClerkQuery from "../hooks/useClerkQuery";
import { useAuth } from "@clerk/clerk-react";

const ActivityLogs = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [edit, setEdit] = useState<boolean>(false);
  var today: any = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = mm + "/" + dd + "/" + yyyy;
  const [selectedDate, setSelectedDate] = useState(dayjs(today));
  const [activityList, setActivityList] = useState<string[]>([]);
  const [search, setSearch] = useState("");
  const { isLoading, data } = useClerkQuery("get-activities", "/activities");
  const {
    // isPending,
    mutate: mutateUpdateActivity,
  } = useMutation({
    mutationFn: updateActivity,
    onSuccess: (data: any) => {
      message.success(data);
      queryClient.invalidateQueries({ queryKey: ["get-activities"] });
    },
  });

  const { isPending: pendingDelete, mutate: mutateDeleteActivity } =
    useMutation({
      mutationFn: deleteActivity,
      onSuccess: (data) => {
        message.success(data);
        queryClient.invalidateQueries({ queryKey: ["get-activities"] });
      },
    });

  const { isPending: pendingCreateLog, mutate: mutateCreateActivityLog } =
    useMutation({
      mutationFn: createActivityLog,
      onSuccess: (data) => {
        message.success(data);
        navigate(-1);
        queryClient.invalidateQueries({ queryKey: ["get-activity-logs"] });
      },
    });
  return (
    <>
      <div
        style={{
          position: "sticky",
          top: "-16px",
          //   backgroundColor: "#F5F5F5",
          zIndex: 99,
          margin: "-16px -16px 0 -16px",
          padding: "0 16px 0 16px",
          backdropFilter: "blur(16px) saturate(180%)",
          WebkitBackdropFilter: "blur(16px) saturate(180%)",
          backgroundColor: "rgba(255, 255, 255, 0.45)",
          border: "1px solid rgba(209, 213, 219, 0.3)",
          borderTop: "none",
        }}
      >
        <Row justify={"space-between"}>
          <Typography.Title
            level={3}
            style={{
              margin: "16px 0 16px 0",
              display: "inline-block",
            }}
          >
            <Button
              type="text"
              onClick={() => navigate(-1)}
              icon={<ArrowLeftOutlined />}
            />
            Healthy Activities
          </Typography.Title>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <DatePicker
              value={selectedDate}
              onChange={(e) => {
                setSelectedDate(e);
              }}
              style={{
                width: "120px",
                // marginBottom: "16px",
                background: "transparent",
              }}
              variant="borderless"
              format={dateFormat}
            />
          </div>
        </Row>
        <div
          style={{
            marginBottom: "16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Input.Search
            placeholder="looking for an activity?"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <div
            style={{ minWidth: "80px", display: "flex", justifyContent: "end" }}
          >
            <AddActivity />
            <Button
              shape="circle"
              style={{ border: "none", boxShadow: "none", marginLeft: "8px" }}
              icon={edit ? <SaveOutlined /> : <EditOutlined />}
              onClick={() => setEdit(!edit)}
            />
          </div>
        </div>
      </div>

      <List
        style={{
          marginBottom: edit ? 0 : "50px",
        }}
        itemLayout="horizontal"
        loading={{ spinning: isLoading, indicator: <CenteredSpin /> }}
        dataSource={data?.data.filter((item: any) =>
          item.activity.toLowerCase().includes(search.toLowerCase())
        )}
        renderItem={(item: any) => (
          <motion.div
            whileInView={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: 20 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            <List.Item style={{ minHeight: "57px" }}>
              {/* <Skeleton avatar title={false} loading={isLoading} active> */}
              {!edit ? (
                <Row style={{ width: "100%" }} justify={"space-between"}>
                  <Col span={22}>
                    <div style={{ paddingLeft: "11px" }}>{item.activity}</div>
                  </Col>
                  <Col
                    span={2}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <Checkbox
                      checked={activityList.includes(item.activity)}
                      onChange={(e) => {
                        if (e.target.checked)
                          setActivityList([...activityList, item.activity]);
                        else
                          setActivityList(
                            activityList.filter(
                              (activity) => activity !== item.activity
                            )
                          );
                      }}
                    />
                  </Col>
                </Row>
              ) : (
                // <List.Item.Meta title={item.name} />
                <Row style={{ width: "100%" }} justify={"space-between"}>
                  <Col span={21}>
                    <Input
                      variant="filled"
                      defaultValue={item.activity}
                      style={{
                        // width: "80%",
                        margin: 0,
                      }}
                      onPressEnter={(e) =>
                        mutateUpdateActivity({
                          activity: e.currentTarget.value,
                          id: item.id,
                          getToken,
                        })
                      }
                      onBlur={(e) =>
                        mutateUpdateActivity({
                          activity: e.currentTarget.value,
                          id: item.id,
                          getToken,
                        })
                      }
                    />
                  </Col>
                  <Col span={2}>
                    <Popconfirm
                      placement="topLeft"
                      title="Delete the activity"
                      description="Would you like to delete this activity?"
                      onConfirm={() =>
                        mutateDeleteActivity({
                          id: item.id,
                          getToken,
                        })
                      }
                      // onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        loading={pendingDelete}
                        style={{
                          backgroundColor: "#FF6961",
                          color: "white",
                          border: "none",
                          boxShadow: "none",
                        }}
                        shape="circle"
                        icon={<DeleteOutlined />}
                      />
                    </Popconfirm>
                  </Col>
                </Row>
              )}

              {/* <div>{item.name}</div> */}
              {/* </Skeleton> */}
            </List.Item>
          </motion.div>
        )}
      />

      {!edit && (
        <div
          style={{
            //   backgroundColor: "#F7CACA",
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            padding: "1rem",
            backdropFilter: "blur(16px) saturate(180%)",
            WebkitBackdropFilter: "blur(16px) saturate(180%)",
            backgroundColor: "rgba(255, 255, 255, 0.45)",
            border: "1px solid rgba(209, 213, 219, 0.3)",
          }}
        >
          <Button
            onClick={() => {
              const createdAt = dayjs(selectedDate).format(dateFormat);
              mutateCreateActivityLog({ activity: activityList, createdAt });
            }}
            loading={pendingCreateLog}
            icon={<PlusOutlined />}
            type="primary"
            block
          >
            Log Activities
          </Button>
        </div>
      )}
    </>
  );
};

export default ActivityLogs;
