import React from "react";
import loadingAnimation from "../../assets/loading-lottie.json";
import Lottie from "react-lottie-player";

export interface CenteredSpinProps {
  size?: "default" | "small" | "large";
  height?: string | number;
}

const CenteredSpin: React.FC<CenteredSpinProps> = ({
  size = "default",
  height = 100,
}) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        height,
      }}
    >
      <div
        style={{ width: size === "large" ? 150 : size === "small" ? 64 : 100 }}
      >
        <Lottie
          play
          animationData={loadingAnimation}
          loop
          rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
        />
      </div>
    </div>
  );
};

export default CenteredSpin;
