import { Button, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import CenteredSpin from "../components/common/CenteredSpin";
import ActivityLogHistoryCard from "../components/activity/ActivityLogHistoryCard";
import useClerkQuery from "../hooks/useClerkQuery";

const DiaryHistory = () => {
  const navigate = useNavigate();
  const { isLoading, data } = useClerkQuery(
    "get-activity-logs",
    "/activityLogs"
  );

  return (
    <>
      <div
        style={{
          position: "sticky",
          top: "-16px",
          //   backgroundColor: "#F5F5F5",
          zIndex: 99,
          margin: "-16px -16px 0 -16px",
          padding: "16px 16px 0 16px",
          backdropFilter: "blur(16px) saturate(180%)",
          WebkitBackdropFilter: "blur(16px) saturate(180%)",
          backgroundColor: "rgba(255, 255, 255, 0.45)",
          border: "1px solid rgba(209, 213, 219, 0.3)",
          borderTop: "none",
        }}
      >
        <Row>
          <Typography.Title
            level={3}
            style={{
              margin: "0 0 16px 0",
              display: "inline-block",
            }}
          >
            <Button
              type="text"
              onClick={() => navigate(-1)}
              icon={<ArrowLeftOutlined />}
            />
            Diary History
          </Typography.Title>
        </Row>
      </div>
      <Row gutter={[8, 8]} style={{ marginTop: "8px" }}>
        {isLoading ? (
          <CenteredSpin />
        ) : (
          data?.data.map((log: any) => (
            <ActivityLogHistoryCard key={log.date} log={log} />
          ))
        )}
      </Row>
    </>
  );
};

export default DiaryHistory;
