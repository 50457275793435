import { FormOutlined, ReadOutlined } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useUser } from "@clerk/clerk-react";
import { useEffect, useState } from "react";

const Dashboard = () => {
  const [messageIsVisible, setMessageIsVisible] = useState(false);
  const [itemsIsVisible, setItemIsVisible] = useState(true);
  const { user } = useUser();
  const navigate = useNavigate();
  const isWife = user?.publicMetadata.wife ?? false;
  const items = [
    {
      id: 1,
      label: "Diary History",
      icon: <ReadOutlined />,
      link: "./diary-history",
    },
    {
      id: 2,
      label: "Diary Entry",
      icon: <ReadOutlined />,
      link: "./diary-entry",
    },
    {
      id: 3,
      label: "Activity Log",
      icon: <FormOutlined />,
      link: "./activity-log",
    },
  ];

  useEffect(() => {
    const lastSeen = localStorage.getItem("lastSeenWelcome");
    const now = Date.now();
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // show once a day

    if (!lastSeen || now - parseInt(lastSeen, 10) > oneDayInMilliseconds) {
      setMessageIsVisible(true);
      setItemIsVisible(false);
      localStorage.setItem("lastSeenWelcome", now.toString());

      setTimeout(() => {
        setMessageIsVisible(false);
        setItemIsVisible(true);
      }, 5000);
    }
  }, []);

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        {messageIsVisible && (
          <motion.div
            whileInView={{
              opacity: messageIsVisible ? 1 : 0,
              display: messageIsVisible ? "block" : "none",
            }}
            initial={{ opacity: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            <Card
              // bordered={false}
              title={
                <div
                // style={{ color: "white" }}
                >
                  {isWife ? "Welcome back bby 🥰" : `Welcome ${user?.fullName}`}
                </div>
              }
              style={{
                // color: "white",
                // backgroundColor: "#93A8D0",
                border: "none",
                backgroundColor: "transparent",
                // boxShadow: "rgba(0, 0, 0, 0.08) 0px 4px 12px",
                // backdropFilter: "blur(7px) saturate(150%)",
                // WebkitBackdropFilter: "blur(7px) saturate(150%)",
                // backgroundColor: "rgba(247, 202, 202, 0.2)",
                // border: "1px solid rgba(209, 213, 219, 0.3)",
              }}
            >
              {isWife ? (
                <>
                  Your husband loves you a lot and cares for you so much. You
                  are appreciated and loved. Thank you for doing your best.❤️
                  <br />
                  With love,
                  <br />
                  Your husband 🥰
                </>
              ) : (
                <>
                  We’re glad you’re here. Our app is designed to support you on
                  your journey towards better mental health and well-being. With
                  a range of tools and resources, you’ll find everything you
                  need to manage your thoughts, emotions, and behaviors
                  effectively.
                </>
              )}
            </Card>
          </motion.div>
        )}
      </Col>

      {items.map((i: any) => {
        return (
          <Col key={i.id} span={24} onClick={() => navigate(i.link)}>
            <motion.div
              whileInView={{
                opacity: itemsIsVisible ? 1 : 0,
                display: itemsIsVisible ? "block" : "none",
                y: itemsIsVisible ? "0" : "100%",
              }}
              initial={{ opacity: 0, y: "100%" }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
            >
              <Card
                bordered={false}
                style={{
                  backgroundColor: "#F7CACA",
                  boxShadow: "rgba(0, 0, 0, 0.08) 0px 4px 12px",
                }}
              >
                <div style={{ display: "inline-block", marginRight: "8px" }}>
                  {i.icon}
                </div>
                {i.label}
              </Card>
            </motion.div>
          </Col>
        );
      })}
    </Row>
  );
};

export default Dashboard;
