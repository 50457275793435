import client from "./client";
import dayjs from "dayjs";
// export const getActivityLogs = async () => {
//   const response = await client.get(`/activityLogs`);
//   return response.data;
// };

export const createActivityLog = async (data: any) => {
  const response = await client.post(
    `/activityLogs`,
    { activity: data.activity, createdAt: data.createdAt },
    {
      headers: {
        Authorization: `Bearer ${await data.getToken()}`,
      },
    }
  );
  return response.data;
};

export const deleteActivityLog = async (data: any) => {
  const [day, month, year] = data.date.split("/");
  const date: Date = new Date(`${year}-${month}-${day}`);
  const response = await client.delete(
    `/activityLogs/${dayjs(date).toISOString().split("T")[0]}`,
    {
      headers: {
        Authorization: `Bearer ${await data.getToken()}`,
      },
    }
  );
  return response.data;
};
