import Lottie from "react-lottie-player";
import underDevelopment from "../assets/under-development.json";
import { Button, Typography } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const UnderDevelopment = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        maxHeight: "85vh",
      }}
    >
      <Typography.Title level={3}>WORKING ON IT 😃</Typography.Title>
      <Typography.Title style={{ marginTop: 0 }}>STAY TUNED~</Typography.Title>
      <Lottie
        style={{ maxWidth: "100%", maxHeight: "90%" }}
        play
        animationData={underDevelopment}
        loop
        rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
      />
      <Button
        onClick={() => navigate("/")}
        type="primary"
        icon={<HomeOutlined />}
        style={{ marginTop: "8px" }}
      >
        Home
      </Button>
    </div>
  );
};

export default UnderDevelopment;
