// import { useAuth, UserButton } from "@clerk/clerk-react";
import {
  //   Avatar,
  ConfigProvider,
  //   Dropdown,
  Layout,
  theme,
  //   Typography,
} from "antd";
import { Outlet } from "react-router-dom";
// import logo from "../assets/logo.png";
import { motion } from "framer-motion";
import { UserButton, useUser } from "@clerk/clerk-react";
// import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
// import logoLeaf from "../assets/logo-leaf.jpeg";
// import logoHand from "../assets/logo-hand.jpeg";
// import background from "../assets/background.jpeg";
const {
  Header,
  // Sider,
  Content,
} = Layout;

const DefaultLayout = () => {
  const { user } = useUser();
  const isWife = user?.publicMetadata.wife ?? false;
  const anim = (variants: any) => {
    return {
      initial: "initial",
      animate: "enter",
      exit: "exit",
      variants,
    };
  };
  const opacity = {
    initial: {
      y: "-100%",
      opacity: 0,
    },
    enter: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
    exit: {
      opacity: 1,
    },
  };
  // const slide = {
  //   initial: {
  //     top: "0",
  //   },
  //   enter: {
  //     top: "100vh",
  //     transition: {
  //       duration: 1,
  //       ease: [0.55, 0, 1, 0.45],
  //     },
  //   },
  //   exit: {
  //     top: "100dvh",
  //   },
  // };
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.defaultAlgorithm,
        token: {
          // colorPrimary: "AA98A9",
          colorPrimary: "#93A9D1",
          // pink: "#F7CACA",
        },

        // components: { Layout: { headerBg: "rgba(255, 255, 255, 0.75)" } },
      }}
    >
      {/* <motion.div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: "#93A9D1",
          width: "100dvw",
          height: "100dvh",
          zIndex: 100,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        {...anim(slide)}
      >
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "50%",
            width: "210px",
            height: "210px",
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={logo} style={{ width: "200px" }} alt="logo" />
        </div>

        <Typography.Title style={{ color: "#F7CACA" }}>
          Dear Self
        </Typography.Title>
      </motion.div> */}
      <motion.div {...anim(opacity)}>
        <Layout
          style={{
            overflow: "hidden",
            height: "100dvh",
            // background: `url(${background})`,
          }}
        >
          <motion.div
            initial={{ y: "-100%", opacity: 0 }}
            whileInView={{ y: "0", opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Header
              style={{
                padding: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                // backdropFilter: "blur(16px) saturate(180%)",
                // WebkitBackdropFilter: "blur(16px) saturate(180%)",
                // backgroundColor: "rgba(255, 255, 255, 0.6)",
                backgroundColor: "#FFFFFF",
                border: "1px solid rgba(209, 213, 219, 0.3)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <img
                  src={"/logo.png"}
                  alt="logo"
                  style={{
                    // width: "48px",
                    height: "28px",
                    marginRight: "8px",
                  }}
                />
                <div
                  style={{
                    fontWeight: 900,
                    fontSize: "1rem",
                    color: "#F7CACA",
                  }}
                >
                  Dear {isWife ? "Wife" : "Self"}
                  {/* Dear Self */}
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <UserButton />
              </div>
            </Header>
          </motion.div>
          <Content
            style={{
              padding: "1rem",
              overflow: "auto",
              // background: `url(${background})`,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </motion.div>
    </ConfigProvider>
  );
};

export default DefaultLayout;
